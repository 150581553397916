import { useEffect, useState } from 'react';
// THIS CUSTOM HOOK IS CREATED FOR USERS CONNECTED NETWORK INFORMATION
const useNetworkType = () => {
  const [networkType, setNetworkType] = useState('');

  useEffect(() => {
    const connection =
      navigator.connection ||
      navigator.mozConnection ||
      navigator.webkitConnection;

    if (connection) {
      setNetworkType(connection.effectiveType);

      const updateNetworkType = () => setNetworkType(connection.effectiveType);

      connection.addEventListener('change', updateNetworkType);

      return () => {
        connection.removeEventListener('change', updateNetworkType);
      };
    }
  }, []);

  return networkType;
};

export default useNetworkType;
