import { Menu } from 'antd';
import Image from 'next/image';
import { useRouter } from 'next/router';
import CustomLink from '../CustomLink';

function Menubar({ menuItems, setIsOpen, onClose }) {
  const router = useRouter();
  const { pathname } = router;

  const items = menuItems
    ?.filter((menu) => menu?.position === 'HEADER')
    ?.map((menu) => ({
      label: (
        <CustomLink
          name={menu?.name}
          url={menu?.url}
          type={menu?.type}
          internalPageType={menu?.internalPageType}
          target={menu?.openInSameTab ? '_self' : '_blank'}
          icon={
            menu?.icon && (
              <Image src={menu?.icon} width={20} height={20} alt={menu?.name} />
            )
          }
        />
      ),
      key: menu?.url
    }));

  return (
    <Menu
      onClick={() => {
        setIsOpen(false);
        onClose();
      }}
      selectedKeys={[`/${pathname?.split('/')?.[1]}`]}
      // mode={isDesktop ? 'horizontal' : 'vertical'}
      mode="vertical"
      items={items}
    />
  );
}
export default Menubar;
