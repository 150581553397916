/* eslint-disable no-nested-ternary */
import { useMatomo } from '@datapunt/matomo-tracker-react';
import { Avatar, Button } from 'antd';
import moment from 'moment';
import { useRouter } from 'next/router';
import { SignOut } from 'phosphor-react';
import {
  actionDimensionIds,
  MATOMO_ACTION,
  MATOMO_CATEGORY,
  MATOMO_NAME,
  ROUTES,
  TRACK_VALUE
} from '../../../common/constants';
import useDeviceInfo from '../../../common/useDeviceInfo';
import useNetworkType from '../../../common/useNetworkInfo';
import { getUserCommonDimensions } from '../../../common/utils';
import { useApp } from '../../../providers/AppContext';
import CustomAnchor from '../../CustomAnchor';
import CaretRight from '../../imageComponent/CaretRight';

function AccountProfile({ shortName, data = [], profileImage, onClose }) {
  const router = useRouter();
  const {
    state: { currentUser }
  } = useApp();
  const { trackEvent } = useMatomo();

  const device = useDeviceInfo();
  const networkType = useNetworkType();

  const formContentDimension = (btnLabel, target) => {
    const userDimensions = getUserCommonDimensions(
      currentUser,
      device,
      networkType
    );

    const contentObjectForVideo = [
      { id: actionDimensionIds.page_source, value: router.asPath },
      { id: actionDimensionIds.cta_target, value: target },
      { id: actionDimensionIds.cta_title, value: btnLabel },
      ...userDimensions
    ];

    return contentObjectForVideo;
  };
  const handleSignOut = async () => {
    onClose();
    router.push(ROUTES.LOGOUT);
    trackEvent({
      category: MATOMO_CATEGORY.BUTTON,
      action: MATOMO_ACTION.CLICK,
      name: MATOMO_NAME.BUTTON_CLICK,
      customDimensions: formContentDimension('logout', ROUTES.LOGOUT),
      value: TRACK_VALUE.SUCCESS
    });
  };

  return (
    <div className="account-profile">
      <div className="account-profile-header">
        <div>
          <Avatar
            {...(profileImage && {
              src: profileImage
            })}
            className="bg-blue-2 label-l text-black-90"
            size="large"
          >
            {shortName}
          </Avatar>

          <div>
            {currentUser?.currentSubscriptionPlan?.planName && (
              <div className="plan-name">
                {currentUser?.currentSubscriptionPlan?.planName}
              </div>
            )}{' '}
            {currentUser?.currentSubscriptionPlan?.startedAt && (
              <div className="plan-date">
                {moment(currentUser?.currentSubscriptionPlan?.startedAt).format(
                  'MMMM YYYY'
                )}
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="profile-menu">
        <ul>
          {data?.map((item) => {
            return (
              <li
                key={item?.id}
                className={
                  router.pathname === `${item?.href ? item?.href : '#'}`
                    ? 'active'
                    : ''
                }
                onClick={onClose}
              >
                <CustomAnchor href={item?.href ? item?.href : '#'}>
                  {item.icon}
                  {item.title}
                  <CaretRight />
                </CustomAnchor>
              </li>
            );
          })}
        </ul>
        <div className="profile-btn">
          <Button
            className="cs-button between"
            size="large"
            type="primary"
            block
            onClick={handleSignOut}
          >
            Logout
            <SignOut size={20} />
          </Button>
        </div>
      </div>
    </div>
  );
}
export default AccountProfile;
