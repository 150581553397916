import { useMatomo } from '@datapunt/matomo-tracker-react';
import { Layout as LayoutAnt } from 'antd';
import { usePathname } from 'next/navigation';
import { useRouter } from 'next/router';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  actionDimensionIds,
  MATOMO_ACTION,
  MATOMO_CATEGORY,
  MATOMO_NAME,
  ROUTES
} from '../common/constants';
import useDeviceInfo from '../common/useDeviceInfo';
import useNetworkType from '../common/useNetworkInfo';
import { getUserCommonDimensions } from '../common/utils';
import { useApp } from '../providers/AppContext';
import Footer from './Footer';
import Header from './Header';
import RouteProgress from './RouteProgress';

function Layout({
  children,
  headerLogo,
  appName,
  headerLogoPosition,
  headerLogoSize,
  menus = [],
  showMenu
}) {
  const router = useRouter();
  const pathname = usePathname();
  const [isLoading, setIsLoading] = useState(true);
  const [oldRouteVal, setOldRouteVal] = useState(router.asPath);
  const [isScrollingUp, setIsScrollingUp] = useState(false);
  const [scrollDepth, setScrollDepth] = useState(0);
  const previousScrollY = useRef(0);
  const device = useDeviceInfo();
  const networkType = useNetworkType();
  const { trackEvent } = useMatomo();
  const pageHeight = useRef(0);
  const {
    state: { currentUser }
  } = useApp();

  const scrollDimensions = useCallback(() => {
    const userDimensions = getUserCommonDimensions(
      currentUser,
      device,
      networkType
    );
    const contentObjectForLink = [
      { id: actionDimensionIds.page_source, value: router.asPath },
      { id: actionDimensionIds.scroll_depth, value: `${scrollDepth}%` },
      ...userDimensions
    ];
    return contentObjectForLink;
  }, [device, networkType, currentUser, scrollDepth, router.asPath]);

  const loginClass = [
    ROUTES.LOGIN,
    ROUTES.SIGN_UP,
    ROUTES.FORGOT_PASSWORD,
    ROUTES.VERIFY_PHONE_NUMBER,
    ROUTES.VERIFY,
    ROUTES.VERIFY_YOUR_EMAIL,
    ROUTES.VERIFICATION,
    ROUTES.ACCOUNT_SETTING,
    ROUTES.BILLING_HISTORY,
    ROUTES.RESET_PASSWORD,
    ROUTES.MANAGE_PLAN
  ].some((path) => pathname.startsWith(path) && pathname !== ROUTES.MANAGE_PLAN)
    ? 'login-page'
    : '';

  useEffect(() => {
    if (router.isReady) {
      setIsLoading(false);
    }
    const handleStart = (routeData) => {
      if (routeData !== oldRouteVal) setIsLoading(true);
    };

    const handleComplete = () => {
      setIsLoading(false);
      setOldRouteVal(() => router.asPath);
    };

    router.events.on('routeChangeStart', handleStart);
    router.events.on('routeChangeComplete', handleComplete);
    router.events.on('routeChangeError', handleComplete);

    return () => {
      router.events.off('routeChangeStart', handleStart);
      router.events.off('routeChangeComplete', handleComplete);
      router.events.off('routeChangeError', handleComplete);
    };
  }, [router, oldRouteVal]);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY;
      const windowHeight = window.innerHeight;
      const documentHeight = document.documentElement.scrollHeight;
      const scrollPercent = Math.round(
        (currentScrollY / (documentHeight - windowHeight)) * 100
      );
      setScrollDepth(scrollPercent);

      const isUp = currentScrollY < previousScrollY.current;
      setIsScrollingUp(isUp);
      previousScrollY.current = currentScrollY;
      if (isScrollingUp) {
        trackEvent({
          category: MATOMO_CATEGORY.SCROLL,
          action: MATOMO_ACTION.SCROLL,
          name: MATOMO_NAME.SCROLL_TOP,
          customDimensions: scrollDimensions()
        });
      } else {
        trackEvent({
          category: MATOMO_CATEGORY.SCROLL,
          action: MATOMO_ACTION.SCROLL,
          name: MATOMO_NAME.SCROLL_BOTTOM,
          customDimensions: scrollDimensions()
        });
      }
    };
    const handleResize = () => {
      pageHeight.current = document.body.scrollHeight;
    };
    window.addEventListener('scroll', handleScroll);
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('resize', handleResize);
    };
  }, [isScrollingUp, trackEvent, router.pathname, scrollDimensions]);
  return (
    <LayoutAnt className={`layout ${loginClass}`}>
      <Header
        menuItems={menus}
        appName={appName}
        headerLogo={headerLogo}
        headerLogoPosition={headerLogoPosition}
        headerLogoSize={headerLogoSize}
        showMenu={showMenu}
      />
      <div className="wrapper">
        {isLoading && <RouteProgress />}
        {children}
      </div>
      <Footer appName={appName} />
    </LayoutAnt>
  );
}
export default Layout;
