import axios from 'axios';
import { groupBy } from 'lodash';
import get from 'lodash/get';
import isNumber from 'lodash/isNumber';
import uniq from 'lodash/uniq';
import moment from 'moment-timezone';
import { CaretRight } from 'phosphor-react';
import AppInfo from '../../package.json';
import {
  ALIGNMENTS,
  ASSET_CATEGORY,
  ASSET_SERVICES,
  COLOR_KEYS,
  CONVERSATION_DATE_FORMAT,
  DATE_FORMATS,
  DEVICES,
  MATOMO_CATEGORY,
  MODULE_TYPES,
  PAGE_TYPE,
  RATIOS,
  REGEX,
  ROUTES,
  THEME_PICKER_COLORS,
  VIDEO_TYPES,
  visitDimensionIds
} from './constants';

export const staticDataApi = axios.create({
  baseURL: `${process.env.NEXT_APP_STATIC_DATA_URL}/static/${process.env.NEXT_APP_WORKSPACE_ID}/${process.env.NEXT_APP_APPID}`
});
export const jsonDataApi = axios.create({
  baseURL: `${process.env.NEXT_APP_STATIC_DATA_URL}/pages/${process.env.NEXT_APP_WORKSPACE_ID}/${process.env.NEXT_APP_APPID}`
});
export const encodeURL = (str) => {
  try {
    const data = str;
    if (!data) return null;
    return encodeURIComponent(str);
  } catch (err) {
    return null;
  }
};

export const getMetaOgImage = (pageType, slug, extension = 'png') => {
  return `${process?.env?.NEXT_APP_STATIC_DATA_URL}/og-images/${
    process.env.NEXT_APP_WORKSPACE_ID
  }/${
    pageType ? `${pageType}/` : ''
  }${slug}.${extension}?t=${new Date().getTime()}`;
};

export const secondsToMinute = (seconds) => {
  if (seconds < 3600) {
    return isNumber(seconds)
      ? `${Math.floor(seconds / 60)}:${Math.floor(seconds % 60)
          .toString()
          .padStart(2, 0)}`
      : null;
  }
  return isNumber(seconds)
    ? `${Math.floor(seconds / 3600)}:${Math.floor((seconds % 3600) / 60)
        .toString()
        .padStart(2, 0)}:${Math.floor(seconds % 60)
        .toString()
        .padStart(2, 0)}`
    : null;
};

export const getVideoProgressInPercentage = (progress, totalLength) => {
  if (isNumber(progress) && isNumber(totalLength) && totalLength !== 0) {
    return (progress * 100) / totalLength;
  }
  return null;
};

// watch later video

// Videos
export const videoListMapper = (video) => ({
  id: video?.id,
  time: video?.video?.information?.durationInSeconds,
  title: video?.title,
  videoDescription: video?.description,
  src: video?.imageThumbnail?.url,
  blurhash: video?.imageThumbnail?.blurhash,
  videoThumbnail: video?.videoThumbnail?.url ?? '',
  href: `${ROUTES.VIDEOS}/${video?.slug}`
});

export const getVideoData = (data) => data?.map(videoListMapper) || [];

// Videos

export const videosMapper = (video, order) => {
  return {
    id: video?.id,
    time: video?.video?.information?.durationInSeconds,
    collection: !!order && `part ${order}`,
    category: video?.topics?.[0]?.name ?? '',
    categoryHref: `${ROUTES.TOPICS}/${video?.topics?.[0]?.slug}`,
    title: video?.title ?? '',
    description: video?.description ?? '',
    isPremium: video?.isPremium ?? false,
    date: video?.displayDate
      ? moment(video?.displayDate).format(DATE_FORMATS.VIDEO_MODULE)
      : '',
    tags: video?.tags?.map(({ name, slug }) => ({ name, slug })) ?? [],
    tagBackground: video?.topics?.[0]?.primaryColor ?? 'bg-n-200',
    src: video?.imageThumbnail?.url ?? '',
    videoThumbnail: video?.videoThumbnail?.url ?? '',
    blurhash: video?.imageThumbnail?.blurhash,
    href: `${ROUTES.VIDEOS}/${video?.slug}`,
    instanceData: {
      id: video?.id,
      slug: video?.slug,
      tags: video?.tags?.map(({ name, slug }) => ({ name, slug })) ?? [],
      title: video?.title,
      topics: video?.topics?.map(({ name, slug }) => ({ name, slug })) ?? []
    },
    instanceId: video?.id,
    instanceType: MATOMO_CATEGORY.VIDEO
  };
};

export const podcastsMapper = (podcast, order) => ({
  id: podcast?.id,
  time: podcast?.audio?.information?.durationInSeconds,
  collection: !!order && `part ${order}`,
  topics: podcast?.topics || [],
  title: podcast?.title ?? '',
  description: podcast?.description ?? '',
  date: podcast?.createdAt
    ? moment(podcast?.createdAt).format(DATE_FORMATS.PODCAST_MODULE)
    : '',
  tags: podcast?.tags?.map(({ name, slug }) => ({ name, slug })) ?? [],
  src: podcast?.imageThumbnail?.url ?? '',
  blurhash: podcast?.imageThumbnail?.blurhash,
  href: `${ROUTES.PODCASTS}/${podcast?.slug}`,
  instanceData: {
    id: podcast?.id,
    slug: podcast?.slug,
    tags: podcast?.tags?.map(({ name, slug }) => ({ name, slug })) ?? [],
    title: podcast?.title,
    topics: podcast?.topics?.map(({ name, slug }) => ({ name, slug })) ?? []
  },
  instanceId: podcast?.id,
  instanceType: MATOMO_CATEGORY.PODCAST
});

const getViewAllVideoLink = (data) => {
  if (data?.moduleData?.settings?.viewAll) {
    let path = ROUTES.VIDEOS;
    if (
      [
        MODULE_TYPES.VIDEO_CAROUSEL,
        MODULE_TYPES.VIDEO_LIST,
        MODULE_TYPES.VIDEO_GRID
      ].includes(data?.type)
    ) {
      const autoGenerateData = data?.moduleData?.config?.autoGenerateByData;
      const query =
        {
          TOPIC: `type=topic&id=${autoGenerateData?.topicId}`,
          TAG: `type=tag&id=${autoGenerateData?.tagId}`,
          COLLECTION: `type=collection&id=${autoGenerateData?.collectionId}`
        }[data?.moduleData?.config?.autoGenerateByType] ?? '';

      if (query) {
        path = `${path}?${query}`;
      }
      return path;
    }
    if (data?.type === MODULE_TYPES.VIDEO_CONTINUE_WATCHING_CAROUSEL) {
      return `${path}?video_type=${VIDEO_TYPES.CONTINUE_WATCHING}`;
    }
  }
  return null;
};

const getViewAllPodcastLink = (data) => {
  if (data?.moduleData?.settings?.viewAll) {
    let path = ROUTES.PODCASTS;
    if ([MODULE_TYPES.PODCAST_LIST].includes(data?.type)) {
      const autoGenerateData = data?.moduleData?.config?.autoGenerateByData;
      const query =
        {
          TOPIC: `type=topic&id=${autoGenerateData?.topicId}`,
          TAG: `type=tag&id=${autoGenerateData?.tagId}`,
          COLLECTION: `type=collection&id=${autoGenerateData?.collectionId}`
        }[data?.moduleData?.config?.autoGenerateByType] ?? '';

      if (query) {
        path = `${path}?${query}`;
      }
      return path;
    }
  }
  return null;
};

const defaultVideoDataHandler = (data) => ({
  sectionHeading: data?.title,
  headerLink: getViewAllVideoLink(data),
  headerDescription: data?.description,
  separator: !!data?.moduleData?.settings?.separator,
  showVideoProgressBar: [
    MODULE_TYPES.VIDEO_CONTINUE_WATCHING_CAROUSEL
  ].includes(data?.type)
    ? true
    : data?.moduleData?.settings?.videoProgressBar,
  videoIds: data?.moduleData?.config?.videos?.map((video) => video?.id),
  actionButton: data?.moduleData?.settings?.actionButton,
  actionTitle: data?.moduleData?.config?.actionTitle,
  data:
    data?.moduleData?.config?.videos?.map((video) =>
      videosMapper(video, video?.order)
    ) || [],
  autoGenerateData: data?.moduleData?.config?.autoGenerateByData,
  autoGenerateByType: data?.moduleData?.config?.autoGenerateByType
});

const defaultPodcastDataHandler = (data) => ({
  sectionHeading: data?.title,
  headerLink: getViewAllPodcastLink(data),
  headerDescription: data?.description,
  separator: !!data?.moduleData?.settings?.separator,
  showPodcastProgressBar: data?.moduleData?.settings?.podcastProgressBar,
  podcastIds: data?.moduleData?.config?.podcasts?.map((podcast) => podcast?.id),
  data:
    data?.moduleData?.config?.podcasts?.map((podcast) =>
      podcastsMapper(podcast, podcast?.order)
    ) || []
});

const getFeatureVideoData = (video) => ({
  id: video?.id,
  date: video?.createdAt
    ? moment(video?.createdAt).format(DATE_FORMATS.VIDEO_MODULE)
    : '',
  time: video?.video?.information?.durationInSeconds,
  tagName: video?.topics?.[0]?.name ?? '',
  tagHref: `${ROUTES.TOPICS}/${video?.topics?.[0]?.slug}`,
  heading: video?.title ?? '',
  description: video?.description ?? '',
  isPremium: video?.isPremium ?? false,
  collections: video?.collections || [],
  tagData:
    video?.tags?.map(({ key, name, slug }) => ({
      id: key,
      name,
      href: `${ROUTES.TAGS}/${slug}`
    })) ?? [],
  tagBackground: video?.topics?.[0]?.primaryColor ?? 'bg-n-200',
  src: video?.imageThumbnail?.url ?? '',
  blurhash: video?.imageThumbnail?.blurhash,
  href: `${ROUTES.VIDEOS}/${video?.slug}`,
  instanceData: {
    id: video?.id,
    title: video?.title ?? '',
    slug: video?.slug,
    tags: video?.tags?.map(({ name, slug }) => ({ name, slug })) ?? [],
    topics: video?.topics?.map(({ name, slug }) => ({ name, slug })) ?? [],
    time: video?.video?.information?.durationInSeconds,
    isPremium: video?.isPremium ?? false,
    collections: video?.collections || []
  }
});

const defaultFeatureVideoDataHandler = (data, multiple = true) =>
  multiple
    ? {
        data: data?.moduleData?.config?.videos?.map(getFeatureVideoData)
      }
    : getFeatureVideoData(data?.moduleData?.config?.video);

const defaultVideoPlayerDataHandler = (data) => ({
  title: data?.moduleData?.config?.video?.title,
  description: data?.moduleData?.config?.video?.description,
  slug: data?.moduleData?.config?.video?.slug,
  category: data?.moduleData?.config?.video?.topics?.[0]?.name,
  categoryHref: `${ROUTES.TOPICS}/${data?.moduleData?.config?.video?.topics?.[0]?.slug}`,
  categoryTagStyle:
    data?.moduleData?.config?.video?.topics?.[0]?.primaryColor ?? 'bg-n-200',
  tags:
    data?.moduleData?.config?.video?.tags?.map(({ key, name, slug }) => ({
      id: key,
      name: name ?? '',
      href: `${ROUTES.TAGS}/${slug}`
    })) ?? [],
  videoIds: data?.moduleData?.config?.video?.id
    ? [data?.moduleData?.config?.video?.id]
    : [],
  isPremium: data?.moduleData?.config?.video?.isPremium,
  requiredPlan: data?.moduleData?.config?.video?.requiredPlan,
  videoAsset: data?.moduleData?.config?.video?.video,
  status: data?.moduleData?.config?.video?.status,
  previewVideo: data?.moduleData?.config?.video?.previewVideo?.url,
  videoMainUrl: data?.moduleData?.config?.video?.video?.url,
  videos: [
    {
      id: data?.moduleData?.config?.video?.id,
      title: data?.moduleData?.config?.video?.title,
      thumb: data?.moduleData?.config?.video?.image?.url,
      tracks:
        data?.moduleData?.config?.video?.textTracks?.map(({ url }) => ({
          kind: 'subtitles',
          label: 'English',
          src: url,
          srcLang: 'en',
          default: false
        })) ?? [],
      url:
        data?.moduleData?.config?.video?.video?.url ||
        data?.moduleData?.config?.video?.previewVideo?.url,
      videoInformation:
        data?.moduleData?.config?.video?.video?.information ||
        data?.moduleData?.config?.video?.previewVideo?.information,
      resources: data?.moduleData?.config?.video?.resources,
      time: data?.moduleData?.config?.video?.information?.durationInSeconds
    }
  ],
  instanceData: {
    title: data?.moduleData?.config?.video?.title ?? '',
    slug: data?.moduleData?.config?.video?.slug,
    tags:
      data?.moduleData?.config?.video?.tags?.map(({ name, slug }) => ({
        name,
        slug
      })) ?? [],
    topics:
      data?.moduleData?.config?.video?.topics?.map(({ name, slug }) => ({
        name,
        slug
      })) ?? [],
    status: data?.moduleData?.config?.video?.status,
    isPremium: data?.moduleData?.config?.video?.isPremium
  }
});

const COLLECTIONS_ASPECT_RATIOS = {
  [RATIOS.MEDIUM]: {
    collectionMd: true
  },
  [RATIOS.LARGE]: {
    collectionLg: true
  }
};

// Collections
export const getCollectionList = (data = []) =>
  data?.map((collection) => ({
    id: collection?.id,
    title: collection?.title,
    coverImg: collection?.thumbnail?.url ?? '',
    blurhash: collection?.thumbnail?.blurhash,
    videoCount: collection?.itemsCount ?? 0,
    href: `${ROUTES.COLLECTIONS}/${collection?.slug}`
  }));

const defaultCollectionDataHandler = (data) => ({
  sectionHeading: data?.title,
  headerLink: data?.moduleData?.settings?.viewAll ? ROUTES.COLLECTIONS : null,
  headerDescription: data?.description,
  separator: !!data?.moduleData?.settings?.separator,
  ...COLLECTIONS_ASPECT_RATIOS?.[data?.moduleData?.config?.aspectRatio],
  data: data?.moduleData?.config?.collections?.map((collection) => ({
    id: collection?.id,
    title: collection?.title,
    description: collection?.description,
    coverImg: collection?.thumbnail?.url ?? '',
    blurhash: collection?.thumbnail?.blurhash,
    ...(data?.moduleData?.settings?.numberOfItems && {
      videoCount: collection?.itemsCount
    }),
    href: `${ROUTES.COLLECTIONS}/${collection?.slug}`
  }))
});
const getFeatureCollectionData = (collection, settings) => ({
  id: collection?.id,
  title: collection?.title ?? '',
  description: collection?.description,
  ...(settings?.numberOfItems && {
    videoCount: collection?.itemsCount
  }),
  img: collection?.thumbnail?.url ?? '',
  blurhash: collection?.thumbnail?.blurhash,
  button: 'View Podcast',
  href: `${ROUTES.COLLECTIONS}/${collection?.slug}`,
  instanceData: {
    id: collection?.id,
    title: collection?.title,
    ...(settings?.numberOfItems && {
      videoCount: collection?.itemsCount
    }),
    slug: collection?.slug
  }
});
const getCollectionDetailData = (collection, _, collectionItems) => ({
  id: collection?.id,
  title: collection?.title ?? '',
  description: collection?.description ?? '',
  img: collection?.thumbnail?.url ?? '',
  blurhash: collection?.thumbnail?.blurhash,
  button: 'View Podcast',
  collectionItemData:
    collectionItems?.map((item) => ({
      id: item?.id,
      type: item?.type,
      order: item?.order,
      sequence: item?.sequence,
      itemData: item?.itemData
    })) ?? [],
  podcastIds: collectionItems
    ?.filter((item) => item?.type === PAGE_TYPE.PODCAST)
    ?.map((item) => item?.id),
  videoIds: collectionItems
    ?.filter((item) => item?.type === PAGE_TYPE.VIDEO)
    ?.map((item) => item?.id)
});

const defaultFeatureCollectionDataHandler = (data, multiple = true) => ({
  ...(multiple
    ? {
        data: data?.moduleData?.config?.collections?.map((collection) =>
          getFeatureCollectionData(collection, data?.moduleData?.settings)
        )
      }
    : getFeatureCollectionData(
        data?.moduleData?.config?.collection,
        data?.moduleData?.settings
      ))
});

const CollectionDetailDataHandler = (data) =>
  getCollectionDetailData(
    data?.moduleData?.config?.collection,
    data?.moduleData?.settings,
    data?.moduleData?.config?.collectionItems ?? []
  );

// Topic

export const getTopicList = (data = []) =>
  data.map((topic) => ({
    id: topic?.id,
    topic: topic?.name ?? '',
    href: `${ROUTES.TOPICS}/${topic?.slug}`,
    backgroundColor: topic?.primaryColor ?? ''
  }));
export const defaultTopicDataHandler = (data) => ({
  sectionHeading: data?.title,
  headerLink: data?.moduleData?.settings?.viewAll ? ROUTES.TOPICS : null,
  headerDescription: data?.description,
  data:
    data?.moduleData?.config?.topics?.map((topic) => ({
      id: topic?.id,
      topic: topic?.name ?? '',
      href: `${ROUTES.TOPICS}/${topic?.slug}`,
      backgroundColor: topic?.primaryColor ?? ''
    })) ?? []
});

const defaultFeatureTopicDataHandler = (data) => ({
  href: `${ROUTES.TOPICS}/${data?.moduleData?.config?.topic?.slug}`,
  topic: data?.moduleData?.config?.topic?.name ?? '',
  backgroundColor: data?.moduleData?.config?.topic?.primaryColor ?? '',
  instanceData: {
    topic: data?.moduleData?.config?.topic?.name ?? ''
  }
});

// Tag
export const getTagList = (data = []) =>
  data?.map((tag) => ({
    id: tag?.key,
    name: tag?.name,
    href: `${ROUTES.TAGS}/${tag?.slug}`
  }));
const defaultTagDataHandler = (data) => ({
  sectionHeading: data?.title,
  headerLink: data?.moduleData?.settings?.viewAll ? ROUTES.TAGS : null,
  headerDescription: data?.description,
  data:
    data?.moduleData?.config?.tags?.map((tag) => ({
      id: tag?.key,
      name: tag?.name ?? '',
      href: `${ROUTES.TAGS}/${tag?.slug}`
    })) ?? []
});

const defaultFeatureTagDataHandler = (data) => ({
  title: data?.moduleData?.config?.tag?.name,
  href: `${ROUTES.TAGS}/${data?.moduleData?.config?.tag.slug}`
});

// CTA
const defaultCTADataHandler = (data) => ({
  img: data?.moduleData?.config?.asset?.url ?? '',
  title: data?.title ?? '',
  description: data?.description ?? '',
  background:
    THEME_PICKER_COLORS[data?.moduleData?.config?.theme]?.backgroundColor,
  contentlight: THEME_PICKER_COLORS[data?.moduleData?.config?.theme]?.textLight,
  primaryAction: data?.moduleData?.config?.primaryAction,
  secondaryAction: data?.moduleData?.config?.secondaryAction,
  blurhash: data?.moduleData?.config?.asset?.blurhash,
  date: [
    data?.moduleData?.config?.eventDate,
    data?.moduleData?.config?.eventTime
  ]
    .filter(Boolean)
    .join(' @ '),
  location: data?.moduleData?.config?.eventLocation,
  instanceData: {
    title: data?.title,
    eventDate: data?.moduleData?.config?.eventDate,
    eventTime: data?.moduleData?.config?.eventTime,
    location: data?.moduleData?.config?.eventLocation
  }
});

const defaultInformationDataHandler = (data) => ({
  size: data?.moduleData?.config?.imageType,
  background:
    THEME_PICKER_COLORS[data?.moduleData?.config?.theme]?.backgroundColor,
  darkBackground:
    THEME_PICKER_COLORS[data?.moduleData?.config?.theme]?.textLight,
  imgSrc: data?.moduleData?.config?.asset?.url ?? '',
  blurhash: data?.moduleData?.config?.asset?.blurhash,
  title: data?.title,
  description: data?.description,
  primaryAction: data?.moduleData?.config?.primaryAction,
  secondaryAction: data?.moduleData?.config?.secondaryAction,
  ...(data?.moduleData?.config?.imageOrientation === ALIGNMENTS.LEFT && {
    reverse: true
  }),
  instanceData: {
    title: data?.title,
    eventDate: data?.moduleData?.config?.eventDate,
    eventTime: data?.moduleData?.config?.eventTime,
    location: data?.moduleData?.config?.eventLocation
  }
});

const defaultCTABannerWithTextHandler = (data) => ({
  primaryAction: data?.moduleData?.config?.primaryAction,
  background:
    THEME_PICKER_COLORS[data?.moduleData?.config?.theme]?.backgroundColor,
  ctaBackgroundDark:
    THEME_PICKER_COLORS[data?.moduleData?.config?.theme]?.textLight,
  ctaBackgroundImage: data?.moduleData?.config?.asset?.url,
  blurhash: data?.moduleData?.config?.asset?.blurhash,
  ...(data?.moduleData?.config?.asset?.url && { ctaBackgroundDark: true }),
  title: data?.title ?? '',
  descriptions: data?.description ?? ''
});

const defaultCTAMapper = (data) => {
  return {
    img: data.asset?.url ?? '',
    title: data?.title ?? '',
    description: data?.description ?? '',
    blurhash: data?.asset?.blurhash,
    internalPageType: data?.internalPageType,
    url: data?.url,
    type: data?.type,
    buttonType: data?.buttonType
  };
};

const defaultCtaItemsDataHandler = (data) => ({
  sectionHeading: data?.title,
  headerDescription: data?.description,
  bottomSeparator: data?.moduleData?.settings?.bottomSeparator,
  data: data?.moduleData?.config?.items?.map(defaultCTAMapper)
});

export const defaultHeroDataHandler = (data) => ({
  ...(data?.moduleData?.config?.asset?.categoryKey === ASSET_CATEGORY.VIDEO && {
    videoSrc: data?.moduleData?.config?.asset?.url
  }),
  ...(data?.moduleData?.config?.asset?.categoryKey === ASSET_CATEGORY.IMAGE && {
    imageSrc: data?.moduleData?.config?.asset?.url
  }),
  title: data?.title ?? '',
  description: data?.description ?? '',
  blurhash: data?.moduleData?.config?.asset?.blurhash,
  date: [
    data?.moduleData?.config?.eventDate,
    data?.moduleData?.config?.eventTime
  ]
    .filter(Boolean)
    .join(' @ '),
  location: data?.moduleData?.config?.eventLocation,
  primaryAction: data?.moduleData?.config?.primaryAction,
  secondaryAction: data?.moduleData?.config?.secondaryAction,
  textAlignment: data?.moduleData?.config?.alignment
});
export const defaultFAQDataHandler = (data) => ({
  title: data?.title,
  subTitle: data?.description,
  data:
    data?.moduleData?.config?.questions?.map(({ question, answer }, id) => ({
      id,
      heading: question,
      cmsContent: answer
    })) ?? []
});
export const defaultSpacerDataHandler = (data) => ({
  desktop: data?.moduleData?.config?.desktop,
  mobile: data?.moduleData?.config?.mobile,
  tablet: data?.moduleData?.config?.tablet,
  tv: data?.moduleData?.config?.tv
});

const defaultHTMLTextBlockDataHandler = (data) => ({
  sectionHeading: data?.title,
  headerDescription: data?.description,
  data: data?.moduleData?.config?.content
});

export const getTableVideoData = (data) =>
  data?.videos?.map((video) => getFeatureVideoData(video)) || [];

const defaultPodcastPlayerDataHandler = (data) => {
  return {
    moduleType: data?.type,
    isAudio:
      data?.moduleData?.config?.podcast?.audio?.service !==
      ASSET_SERVICES.PEERTUBE,
    podcastId: data?.moduleData?.config?.podcast?.id,
    podcastAsk: data?.moduleData?.settings?.podcastAsk,
    podcastShare: data?.moduleData?.settings?.podcastShare,
    collection: !!data?.order && `part ${data?.order}`,
    title: data?.moduleData?.config?.podcast?.title,
    status: data?.moduleData?.config?.podcast?.status,
    description: data?.moduleData?.config?.podcast?.description,
    requiredPlan: data?.moduleData?.config?.podcast?.requiredPlan,
    audioAsset: data?.moduleData?.config?.podcast?.audio,
    isPremium: data?.moduleData?.config?.podcast?.isPremium,
    ...(data?.moduleData?.config?.podcast?.topics?.[0] && {
      category: data?.moduleData?.config?.podcast?.topics?.[0]?.name,
      categoryHref: `${ROUTES.TOPICS}/${data?.moduleData?.config?.podcast?.topics?.[0]?.slug}`,
      categoryTagStyle:
        data?.moduleData?.config?.podcast?.topics?.[0]?.primaryColor ??
        'bg-n-200'
    }),
    tags:
      data?.moduleData?.config?.podcast?.tags?.map(({ key, name, slug }) => ({
        id: key,
        name: name ?? '',
        href: `${ROUTES.TAGS}/${slug}`
      })) ?? [],
    topics:
      data?.moduleData?.config?.podcast?.topics?.map(({ key, name, slug }) => ({
        id: key,
        name: name ?? '',
        href: `${ROUTES.TOPICS}/${slug}`
      })) ?? [],
    // TODO: handle ID
    // videoIds: data?.moduleData?.config?.video?.id
    //   ? [data?.moduleData?.config?.video?.id]
    //   : [],
    videos: [
      {
        id: data?.moduleData?.config?.podcast?.id,
        title: data?.moduleData?.config?.podcast?.title,
        thumb: data?.moduleData?.config?.podcast?.image?.url,
        blurhash: data?.moduleData?.config?.podcast?.image?.blurhash,
        tracks:
          data?.moduleData?.config?.podcast?.textTracks?.map(({ url }) => ({
            kind: 'subtitles',
            label: 'English',
            src: url,
            srcLang: 'en',
            default: false
          })) ?? [],
        url:
          data?.moduleData?.config?.podcast?.video?.url ||
          data?.moduleData?.config?.podcast?.previewVideo?.url,
        videoInformation:
          data?.moduleData?.config?.podcast?.video?.information ||
          data?.moduleData?.config?.podcast?.previewVideo?.information,
        previewVideo: data?.moduleData?.config?.podcast?.previewVideo?.url
      }
    ],
    audios: [
      {
        id: data?.moduleData?.config?.podcast?.id,
        title: data?.moduleData?.config?.podcast?.title,
        thumb: data?.moduleData?.config?.podcast?.image?.url,
        blurhash: data?.moduleData?.config?.podcast?.image?.blurhash,
        tracks:
          data?.moduleData?.config?.podcast?.textTracks?.map(({ url }) => ({
            kind: 'subtitles',
            label: 'English',
            src: url,
            srcLang: 'en',
            default: false
          })) ?? [],
        url:
          data?.moduleData?.config?.podcast?.audio?.url ||
          data?.moduleData?.config?.podcast?.previewAudio?.url,
        audioInformation:
          data?.moduleData?.config?.podcast?.audio?.information ||
          data?.moduleData?.config?.podcast?.previewAudio?.information,
        previewAudio: data?.moduleData?.config?.podcast?.previewAudio?.url
      }
    ]
  };
};

const getFeaturedFormData = (form) => ({
  id: form?.id,
  title: form?.title,
  description: form?.description,
  href: `${ROUTES?.FORMS}/${form?.slug}`,
  // prayerCount: form?.prayerCount,
  cta: form?.ctas ?? null,
  fields: form?.fields
    ? form?.fields?.sort(function (a, b) {
        return a?.order - b?.order;
      })
    : null,
  submitCount: form?.submitCount,
  type: form?.type,
  instanceData: {
    id: form?.id,
    slug: form?.slug,
    isSigned: '',
    formType: form?.type,
    title: form?.title
  },
  instanceId: form?.id,
  instanceType: MATOMO_CATEGORY.FORM
});

export const defaultFormsDataHandler = (data, multiple = true) => {
  return multiple
    ? {
        data: data?.moduleData?.config?.forms?.map(getFeaturedFormData),
        sectionHeading: data?.title,
        headerDescription: data?.description
      }
    : {
        data: getFeaturedFormData(data?.moduleData?.config?.form),
        sectionHeading: data?.title,
        headerDescription: data?.description
      };
};

const defaultSubscriptionHeaderDataHandler = (data) => ({
  sectionHeading: data?.title,
  primaryAction: data?.moduleData?.settings?.action
});

const defaultSubscriptionBlockDataHandler = (data) => ({
  title: data?.title,
  description: data?.description,
  primaryAction: data?.moduleData?.settings?.action,
  thumb: data?.moduleData?.config?.image?.url,
  blurhash: data?.moduleData?.config?.image?.blurhash
});

const defaultArticleDataHandler = (data) => ({
  sectionHeading: data?.title,
  headerDescription: data?.description,
  title: data?.moduleData?.settings?.title,
  description: data?.moduleData?.settings?.description,
  actionName: data?.moduleData?.config?.actionName,
  actionType: data?.moduleData?.config?.actionType
});

const MODULES = {
  // Videos
  [MODULE_TYPES.VIDEO_CONTINUE_WATCHING_CAROUSEL]: (data) => ({
    id: data?.id,
    type: MODULE_TYPES.VIDEO_CONTINUE_WATCHING_CAROUSEL,
    data: defaultVideoDataHandler(data),
    show: !!data?.moduleData?.config?.videos?.length
  }),
  [MODULE_TYPES.VIDEO_CAROUSEL]: (data) => ({
    id: data?.id,
    type: MODULE_TYPES.VIDEO_CAROUSEL,
    data: defaultVideoDataHandler(data),
    show: !!data?.moduleData?.config?.videos?.length
  }),
  [MODULE_TYPES.VIDEO_LIST]: (data) => ({
    id: data?.id,
    type: MODULE_TYPES.VIDEO_LIST,
    data: defaultVideoDataHandler(data),
    show: !!data?.moduleData?.config?.videos?.length
  }),
  [MODULE_TYPES.VIDEO_GRID]: (data) => ({
    id: data?.id,
    type: MODULE_TYPES.VIDEO_GRID,
    data: defaultVideoDataHandler(data),
    show: !!data?.moduleData?.config?.videos?.length
  }),
  [MODULE_TYPES.FEATURED_VIDEO]: (data) => ({
    id: data?.id,
    type: MODULE_TYPES.FEATURED_VIDEO,
    data: defaultFeatureVideoDataHandler(data, false),
    show: !!data?.moduleData?.config?.video,
    showCTA: data?.moduleData?.settings?.videoCTA
  }),
  [MODULE_TYPES.FEATURED_VIDEO_SLIDER]: (data) => ({
    id: data?.id,
    type: MODULE_TYPES.FEATURED_VIDEO_SLIDER,
    data: {
      showIndicator: data?.moduleData?.settings?.carouselIndicator,
      ...defaultFeatureVideoDataHandler(data)
    },
    show: !!data?.moduleData?.config?.videos?.length,
    showCTA: data?.moduleData?.settings?.videoCTA
  }),
  [MODULE_TYPES.VIDEO_PLAYER]: (data) => ({
    id: data?.id,
    type: MODULE_TYPES.VIDEO_PLAYER,
    data: defaultVideoPlayerDataHandler(data),
    show: !!data?.moduleData?.config?.video
  }),
  [MODULE_TYPES.PODCAST_PLAYER]: (data) => ({
    id: data?.id,
    type: MODULE_TYPES.PODCAST_PLAYER,
    data: defaultPodcastPlayerDataHandler(data),
    show: !!data?.moduleData?.config?.podcast
  }),
  // Podcasts
  [MODULE_TYPES.PODCAST_LIST]: (data) => ({
    id: data?.id,
    type: MODULE_TYPES.PODCAST_LIST,
    data: defaultPodcastDataHandler(data),
    show: !!data?.moduleData?.config?.podcasts?.length
  }),
  // Collections
  [MODULE_TYPES.COLLECTION_CAROUSEL]: (data) => ({
    id: data?.id,
    type: MODULE_TYPES.COLLECTION_CAROUSEL,
    data: defaultCollectionDataHandler(data),
    show: !!data?.moduleData?.config?.collections?.length
  }),
  [MODULE_TYPES.COLLECTION_PILL_CAROUSEL]: (data) => ({
    id: data?.id,
    type: MODULE_TYPES.COLLECTION_PILL_CAROUSEL,
    data: defaultCollectionDataHandler(data),
    show: !!data?.moduleData?.config?.collections?.length
  }),
  [MODULE_TYPES.COLLECTION_LIST]: (data) => ({
    id: data?.id,
    type: MODULE_TYPES.COLLECTION_LIST,
    data: defaultCollectionDataHandler(data),
    show: !!data?.moduleData?.config?.collections?.length
  }),
  [MODULE_TYPES.COLLECTION_GRID]: (data) => ({
    id: data?.id,
    type: MODULE_TYPES.COLLECTION_GRID,
    data: defaultCollectionDataHandler(data),
    show: !!data?.moduleData?.config?.collections?.length
  }),
  [MODULE_TYPES.FEATURED_COLLECTION]: (data) => ({
    id: data?.id,
    type: MODULE_TYPES.FEATURED_COLLECTION,
    data: defaultFeatureCollectionDataHandler(data, false),
    show: !!data?.moduleData?.config?.collection,
    showCta: {
      showImage: true
    }
  }),
  [MODULE_TYPES.COLLECTION_DETAIL]: (data) => ({
    id: data?.id,
    type: MODULE_TYPES.COLLECTION_DETAIL,
    data: CollectionDetailDataHandler(data, false)
  }),
  [MODULE_TYPES.FEATURED_COLLECTION_SLIDER]: (data) => ({
    id: data?.id,
    type: MODULE_TYPES.FEATURED_COLLECTION_SLIDER,
    data: defaultFeatureCollectionDataHandler(data),
    show: !!data?.moduleData?.config?.collections?.length,
    showCta: {
      showImage: data?.moduleData?.settings?.collectionImage
    }
  }),
  // Topics
  [MODULE_TYPES.TOPIC_CAROUSEL]: (data) => ({
    id: data?.id,
    type: MODULE_TYPES.TOPIC_CAROUSEL,
    data: defaultTopicDataHandler(data),
    show: !!data?.moduleData?.config?.topics?.length
  }),
  [MODULE_TYPES.TOPIC_LIST]: (data) => ({
    id: data?.id,
    type: MODULE_TYPES.TOPIC_LIST,
    data: defaultTopicDataHandler(data),
    show: !!data?.moduleData?.config?.topics?.length
  }),
  [MODULE_TYPES.TOPIC_GRID]: (data) => ({
    id: data?.id,
    type: MODULE_TYPES.TOPIC_GRID,
    data: defaultTopicDataHandler(data),
    show: !!data?.moduleData?.config?.topics?.length
  }),
  [MODULE_TYPES.FEATURED_TOPIC]: (data) => ({
    id: data?.id,
    type: MODULE_TYPES.FEATURED_TOPIC,
    data: defaultFeatureTopicDataHandler(data),
    show: !!data?.moduleData?.config?.topic,
    extraProps: {
      landing: true
    }
  }),
  // Tag
  [MODULE_TYPES.TAG_CAROUSEL]: (data) => ({
    // eslint-disable-next-line react/destructuring-assignment
    id: data?.id,
    type: MODULE_TYPES.TAG_CAROUSEL,
    data: defaultTagDataHandler(data),
    show: !!data?.moduleData?.config?.tags?.length,
    extraProps: {
      className: 'text-md tag-xs bg-n-700 b-0 text-white-90 mr-4',
      icon: <CaretRight size={16} />
    }
  }),
  [MODULE_TYPES.TAG_CLOUD]: (data) => ({
    // eslint-disable-next-line react/destructuring-assignment
    id: data?.id,
    type: MODULE_TYPES.TAG_CLOUD,
    data: defaultTagDataHandler(data),
    show: !!data?.moduleData?.config?.tags?.length,
    extraProps: {
      className: 'text-md tag-xs bg-n-700 b-1 b-n-500 text-white-90 mr-4',
      icon: <CaretRight size={16} />
    }
  }),
  [MODULE_TYPES.FEATURED_TAG]: (data) => ({
    // eslint-disable-next-line react/destructuring-assignment
    id: data?.id,
    type: MODULE_TYPES.FEATURED_TAG,
    data: defaultFeatureTagDataHandler(data),
    show: !!data?.moduleData?.config?.tag
  }),
  // CTA
  [MODULE_TYPES.CTA_BLOCK]: (data) => ({
    id: data?.id,
    type: MODULE_TYPES.CTA_BLOCK,
    data: defaultCTADataHandler(data)
  }),
  [MODULE_TYPES.CTA_BANNER]: (data) => ({
    id: data?.id,
    type: MODULE_TYPES.CTA_BANNER,
    data: {
      title: data?.title,
      primaryAction: data?.moduleData?.config?.primaryAction,
      foregroundImages: [
        {
          device: DEVICES.DESKTOP,
          src: data?.moduleData?.config?.foregroundImage?.large?.url,
          title: data?.moduleData?.config?.foregroundImage?.large?.title
        },
        {
          device: DEVICES.TABLET,
          src: data?.moduleData?.config?.foregroundImage?.medium?.url,
          title: data?.moduleData?.config?.foregroundImage?.medium?.title
        },
        {
          device: DEVICES.MOBILE,
          src: data?.moduleData?.config?.foregroundImage?.small?.url,
          title: data?.moduleData?.config?.foregroundImage?.small?.title
        }
      ],
      backgroundImages: {
        [DEVICES.DESKTOP]:
          data?.moduleData?.config?.backgroundImage?.large?.url,
        [DEVICES.MOBILE]: data?.moduleData?.config?.backgroundImage?.small?.url,
        [DEVICES.TABLET]: data?.moduleData?.config?.backgroundImage?.medium?.url
      },
      imageHeights: {
        [DEVICES.DESKTOP]: data?.moduleData?.config?.assetHeight?.desktop,
        [DEVICES.MOBILE]: data?.moduleData?.config?.assetHeight?.mobile,
        [DEVICES.TABLET]: data?.moduleData?.config?.assetHeight?.tablet
      }
    }
  }),
  [MODULE_TYPES.CTA_BUTTON_LIST]: (data) => ({
    id: data?.id,
    type: MODULE_TYPES.CTA_BUTTON_LIST,
    data: defaultCtaItemsDataHandler(data)
  }),
  [MODULE_TYPES.CTA_BANNER_WITH_TEXT]: (data) => ({
    id: data?.id,
    type: MODULE_TYPES.CTA_BANNER_WITH_TEXT,
    data: defaultCTABannerWithTextHandler(data)
  }),
  [MODULE_TYPES.HERO]: (data) => ({
    id: data?.id,
    type: MODULE_TYPES.HERO,
    data: defaultHeroDataHandler(data)
  }),
  [MODULE_TYPES.INFORMATION]: (data) => ({
    id: data?.id,
    type: MODULE_TYPES.INFORMATION,
    data: defaultInformationDataHandler(data)
  }),
  [MODULE_TYPES.FAQ]: (data) => ({
    id: data?.id,
    type: MODULE_TYPES.FAQ,
    data: defaultFAQDataHandler(data)
  }),
  [MODULE_TYPES.SPACER]: (data) => ({
    id: data?.id,
    type: MODULE_TYPES.SPACER,
    data: defaultSpacerDataHandler(data)
  }),
  [MODULE_TYPES.HTML_TEXT_BLOCK]: (data) => ({
    id: data?.id,
    type: MODULE_TYPES.HTML_TEXT_BLOCK,
    data: defaultHTMLTextBlockDataHandler(data)
  }),
  [MODULE_TYPES.FORM_CAROUSEL]: (data) => ({
    id: data?.id,
    type: MODULE_TYPES.FORM_CAROUSEL,
    data: defaultFormsDataHandler(data),
    showCta: {
      share: data?.moduleData?.settings?.formShare
      // prayer: data?.moduleData?.settings?.formPrayer
    }
  }),
  [MODULE_TYPES.FORM_DISPLAY]: (data) => ({
    id: data?.id,
    type: MODULE_TYPES.FORM_DISPLAY,
    data: defaultFormsDataHandler(data, false)
  }),
  [MODULE_TYPES.SUBSCRIPTION_HEADER]: (data) => ({
    id: data?.id,
    type: MODULE_TYPES.SUBSCRIPTION_HEADER,
    data: defaultSubscriptionHeaderDataHandler(data, false)
  }),
  [MODULE_TYPES.SUBSCRIPTION_BLOCK]: (data) => ({
    id: data?.id,
    type: MODULE_TYPES.SUBSCRIPTION_BLOCK,
    data: defaultSubscriptionBlockDataHandler(data, false)
  }),
  [MODULE_TYPES.SUBSCRIPTION_LIST]: (data) => ({
    id: data?.id,
    type: MODULE_TYPES.SUBSCRIPTION_LIST,
    data: {
      title: data?.title,
      description: data?.description,
      plans: data?.moduleData?.config?.subscriptionPlans ?? [],
      keyField: data?.moduleData?.config?.key
    }
  }),
  // article
  [MODULE_TYPES.ARTICLE_LIST]: (data) => ({
    id: data?.id,
    type: MODULE_TYPES.ARTICLE_LIST,
    data: defaultArticleDataHandler(data)
  })
};

export default MODULES;

export const getShortName = (firstName = '', lastName = '') => {
  if (firstName && lastName) {
    return `${firstName?.charAt(0)}${lastName?.charAt(0)}`;
  }
};

export const formatPhoneNumber = (str) => {
  // Filter only numbers from the input
  const cleaned = `${str}`?.replace(/\D/g, '');

  // Check if the input is of correct length
  const match = cleaned?.match(/^(\d{3})(\d{3})(\d{4})$/);

  if (match) {
    return `(${match[1]}) ${match[2]}-${match[3]}`;
  }

  return null;
};

export const setCookies = (name, value, expDays = 30) => {
  const date = new Date();
  date.setTime(date.getTime() + expDays * 24 * 60 * 60 * 1000);
  document.cookie = `${name}=${value}`;
};

export function getCookie(name) {
  // eslint-disable-next-line no-undef
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) {
    return parts.pop().split(';').shift();
  }
  return null;
}

export function deleteCookies(name) {
  document.cookie = `${name}=;max-age=0;`;
}

export const encode = (data) => btoa(JSON.stringify(data));
export const decode = (str, def) => {
  try {
    const data = atob(str);
    if (!data) return null;
    return JSON.parse(data);
  } catch (err) {
    return def;
  }
};

export const decodeURL = (str) => {
  try {
    const data = str;
    if (!data) return null;
    return decodeURIComponent(str);
  } catch (err) {
    return null;
  }
};

export const handlePageError = (error, configs = {}) => {
  if (error && error?.graphQLErrors?.length) {
    const code = get(error?.graphQLErrors?.[0], 'extensions.code');
    if (code === 'PAGE_NOT_FOUND') {
      return {
        notFound: true
      };
    }
    if (['UNAUTHORIZED', 'LOGIN_REQUIRED'].includes(code))
      return {
        redirect: {
          permanent: false,
          destination: ROUTES.LOGIN
        },
        props: {},
        ...configs
      };
    if (code === 'FORBIDDEN')
      return {
        redirect: {
          permanent: false,
          destination: ROUTES?.FORBIDDEN
        },
        props: {},
        ...configs
      };
  }

  return {
    redirect: {
      destination: ROUTES.INTERNAL_SERVER_ERROR,
      permanent: false
    }
  };
};

export const isDarkMode = () =>
  // eslint-disable-next-line no-undef
  typeof window !== 'undefined' &&
  window?.matchMedia?.('(prefers-color-scheme: dark)')?.matches;

export const queyFilter = (query = {}) =>
  Object.fromEntries(Object.entries(query).filter(([, value]) => !!value));

export async function fileUpload(signedUrl, image, onUploadProgress) {
  try {
    return new Promise((resolve) => {
      // eslint-disable-next-line no-undef
      const xhr = new XMLHttpRequest();
      xhr?.open('PUT', signedUrl);
      xhr?.setRequestHeader('Content-Type', image?.type);
      xhr?.addEventListener('readystatechange', function () {
        if (this?.readyState === 4) {
          resolve(xhr?.response);
        }
      });
      if (onUploadProgress) {
        xhr.upload.onprogress = (e) => {
          let percentComplete = 0;
          percentComplete = Math?.ceil((e?.loaded / e?.total) * 100);
          onUploadProgress(percentComplete);
        };
      }
      xhr?.send(image);
    });
  } catch (error) {
    throw Error('Something went wrong while uploading file!');
  }
}

export const getBase64 = (img, callback) => {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result));
  reader.readAsDataURL(img);
};

export const getImageUrl = (url, { height = 100, width = 100 } = {}) => {
  if (!url) return url;
  const newUrl = new URL(url);
  newUrl.searchParams.set('height', height);
  newUrl.searchParams.set('width', width);

  return newUrl.href;
};

export class Storage {
  static keys = {
    COOKIE_CONSENT: 'cookieConsent',
    VIDEO_WATCH_COUNT: 'videoWatchCount',
    DEVICE_ID: 'deviceId'
  };

  static get(key) {
    const nameEQ = `${key}=`;
    const ca = document.cookie.split(';');
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === ' ') c = c.substring(1, c.length);
      if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
  }

  static set({ key, value, domain, days = 99999, path = '/' }) {
    let expires;
    if (days) {
      const date = new Date();
      date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
      expires = `; expires=${date.toGMTString()}`;
    } else expires = '';

    const dir = path || '/';
    if (domain) {
      document.cookie = `${key}=${value}${expires}; domain=${domain}; path=${dir};`;
    } else {
      document.cookie = `${key}=${value}${expires}; path=${dir};`;
    }
  }

  static remove(key) {
    if (typeof window !== 'undefined')
      Storage.set({ key, value: '', days: -1 });
  }
}

export const getUniqueVideoPodcastIds = (modules) => {
  let videoId = [];
  let podcastId = [];
  modules?.filter(Boolean)?.forEach(({ data }) => {
    videoId = videoId.concat(data?.videoIds ?? []);
    podcastId = podcastId.concat(data?.podcastIds ?? []);
  });
  return { videoIds: uniq(videoId), podcastIds: uniq(podcastId) };
};

export const formValidatorRules = {
  required: (validationMessage) => ({
    async validator(_, value) {
      if (!value?.trim()) {
        throw new Error(validationMessage);
      }
    }
  }),
  email: () => ({
    validator(rule, value) {
      if (!value) {
        return Promise?.resolve();
      }
      if (!REGEX?.EMAIL?.test(value)) {
        // eslint-disable-next-line prefer-promise-reject-errors
        return Promise?.reject('The input is not valid E-mail!');
      }
      return Promise?.resolve();
    }
  }),
  name: () => ({
    validator(rule, value) {
      if (!value) {
        return Promise?.resolve();
      }
      if (!REGEX?.NAME?.test(value)) {
        // eslint-disable-next-line prefer-promise-reject-errors
        return Promise?.reject('Please enter valid name');
      }
      return Promise?.resolve();
    }
  }),
  number: () => ({
    validator(rule, value) {
      if (!value) {
        return Promise?.resolve();
      }
      if (!Number(value) || !REGEX?.NUMBER?.test(Number(value))) {
        // eslint-disable-next-line prefer-promise-reject-errors
        return Promise?.reject('Should be a valid Number');
      }
      return Promise?.resolve();
    }
  }),
  username: () => ({
    validator(rule, value) {
      if (!value) {
        return Promise?.resolve();
      }
      if (!REGEX?.USERNAME?.test(value)) {
        // eslint-disable-next-line prefer-promise-reject-errors
        return Promise?.reject('Please enter valid username');
      }
      return Promise?.resolve();
    }
  }),
  zipCode: () => ({
    validator(rule, value) {
      if (!value?.trim()) {
        // eslint-disable-next-line prefer-promise-reject-errors
        return Promise?.reject('Please enter ZIP code');
      }
      if (
        value?.length < 1 ||
        value?.length > 10 ||
        parseInt(value, 10) === 0
      ) {
        // eslint-disable-next-line prefer-promise-reject-errors
        return Promise?.reject('Should be less than 10 characters');
      }
      return Promise?.resolve();
    }
  })
};

export const getConversationTime = (time, zone) => {
  if (time && zone) {
    let zoneFormat = 'z';
    const timeWithZone = moment(time).tz(zone);
    const abbr = timeWithZone.zoneAbbr();
    if (/[0-9]/.test(abbr)) zoneFormat = 'Z';
    return timeWithZone.format(`h:mm A (${zoneFormat})`);
  }
  return '';
};

export const getConversationDateRange = (startDate, endDate, zone) => {
  if (startDate && endDate && zone) {
    const start = moment(startDate).tz(zone);
    const end = moment(endDate).tz(zone);
    const diff = end.diff(start, 'days');
    if (diff === 0) return start.format(CONVERSATION_DATE_FORMAT);
    return `${start.format(CONVERSATION_DATE_FORMAT)} - ${end.format(
      CONVERSATION_DATE_FORMAT
    )}`;
  }
  return '';
};

export const withNextVideoQuery = (path, slug, order, type) => {
  if (!path) return;
  const [pathname, queryString = ''] = path?.split('?');
  const query = new URLSearchParams(`${queryString}`);
  query.set(
    'next',
    encode({
      slug,
      type,
      order
    })
  );
  return `${pathname}?${query?.toString()}`;
};

const DEFAULT_COLORS = `:root {
  ${Object.values(COLOR_KEYS)
    ?.map(({ color, variable }) => `${variable}: ${color};`)
    ?.join('\n')}
}`;

export const injectColorsStyleString = (config) => {
  if (!config) return DEFAULT_COLORS;
  const colors = config?.COLORS?.value;

  if (!colors || !Object.keys(colors)?.length) return DEFAULT_COLORS;

  return `:root {
    ${Object.entries(COLOR_KEYS)
      ?.map(
        ([key, { color, variable }]) => `${variable}: ${colors[key] || color};`
      )
      ?.join('\n')}
  }`;
};

const DEFAULT_PRIMARY_FONTS = `
@font-face {
  font-family: 'Primary';
  src: url('/fonts/FoundersGrotesk-Regular.woff2') format('woff2');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Primary';
  src: url('/fonts/FoundersGrotesk-Medium.woff2') format('woff2');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Primary';
  src: url('/fonts/FoundersGrotesk-Light.woff2') format('woff2');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Primary';
  src: url('/fonts/FoundersGrotesk-Bold.woff2') format('woff2');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Primary';
  src: url('/fonts/FoundersGrotesk-Semibold.woff2') format('woff2');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}
`;

const DEFAULT_SECONDARY_FONTS = `
@font-face {
  font-family: 'Secondary';
  src: url('/fonts/Sanomat-Regular.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Secondary';
  src: url('/fonts/Sanomat-Semibold.woff2') format('woff2');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Secondary';
  src: url('/fonts/Sanomat-Light.woff2') format('woff2');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
`;

const DEFAULT_TERTIARY_FONTS = `
@font-face {
  font-family: 'Tertiary';
  src: url('/fonts/FoundersGroteskMono-Regular.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
`;

const DEFAULT_FONTS = [
  DEFAULT_PRIMARY_FONTS,
  DEFAULT_SECONDARY_FONTS,
  DEFAULT_TERTIARY_FONTS
].join('\n');

const createFont = (fonts, name) => {
  return (
    fonts
      ?.map((font) => {
        const fontUrlChunks = font?.fontUrl?.split('.');
        const fontExtension = fontUrlChunks[fontUrlChunks?.length - 1];
        return `
  @font-face {
    font-family: '${name}';
    src: url('${font?.fontUrl}') format('${fontExtension}');
    font-weight: ${font?.weight};
    font-style: ${font?.style};
    font-display: swap;
  }
  `;
      })
      ?.join('\n') || ''
  );
};

export const injectFontsStyleString = (config) => {
  if (!config) return DEFAULT_FONTS;
  const fonts = config?.FONTS?.value;
  if (!fonts || !fonts?.length) return DEFAULT_FONTS;

  const { PRIMARY, SECONDARY, TERTIARY } = groupBy(fonts, 'type');

  const primaryFonts = PRIMARY
    ? createFont(PRIMARY, 'Primary') || DEFAULT_PRIMARY_FONTS
    : DEFAULT_PRIMARY_FONTS;
  const secondaryFonts = SECONDARY
    ? createFont(SECONDARY, 'Secondary') || DEFAULT_SECONDARY_FONTS
    : DEFAULT_SECONDARY_FONTS;
  const tertiaryFonts = TERTIARY
    ? createFont(TERTIARY, 'Tertiary') || DEFAULT_TERTIARY_FONTS
    : DEFAULT_TERTIARY_FONTS;

  return [primaryFonts, secondaryFonts, tertiaryFonts].join(' ');
};

export const getAddress = (place) => {
  const address = {
    street_number: '',
    route: '',
    sublocality_level_2: '',
    sublocality_level_1: '',
    locality: '',
    administrative_area_level_1: '',
    country: '',
    postal_code: '',
    coords: null
  };

  if (place?.address_components?.length > 0) {
    place?.address_components?.forEach(
      ({ types, long_name: lName, short_name: sName }) => {
        const key = Object.keys(address)?.find((type) => types?.includes(type));
        if (key) {
          address[key] = ['administrative_area_level_1', 'country'].includes(
            key
          )
            ? sName
            : lName;
        }
      }
    );
    if (place?.geometry?.location)
      address.coords = {
        lat: place?.geometry?.location?.lat(),
        lng: place?.geometry?.location?.lng()
      };
  }

  return {
    addressLine1: [
      address?.street_number,
      address?.route,
      address?.sublocality_level_2
    ]
      .filter(Boolean)
      .join(', '),
    addressLine2: address?.sublocality_level_1,
    city: address?.locality,
    state: address?.administrative_area_level_1,
    zipcode: address?.postal_code
  };
};

export const isEmpty = (value) => {
  return (
    value == null || (typeof value === 'string' && value.trim().length === 0)
  );
};

export const getInitials = (...args) => {
  return args
    .filter(Boolean)
    .map((value) => value?.[0]?.toString()?.toUpperCase())
    .join('');
};

export const handleDatePacificFormat = (date) => {
  const convertedDate = moment(date).tz('US/Pacific');
  const formattedDate = convertedDate.format('DD MMMM YYYY, h:mm A z');
  return formattedDate;
};

export const getBrowserInfo = () => {
  const { userAgent } = navigator;
  let browserName = 'Unknown';
  let version = 'Unknown';

  if (
    userAgent.includes('Chrome') &&
    !userAgent.includes('Edg') &&
    !userAgent.includes('OPR')
  ) {
    browserName = 'Chrome';
    version = userAgent.match(/Chrome\/(\d+\.\d+)/)?.[1];
  } else if (userAgent.includes('Safari') && !userAgent.includes('Chrome')) {
    browserName = 'Safari';
    version = userAgent.match(/Version\/(\d+\.\d+)/)?.[1];
  } else if (userAgent.includes('Firefox')) {
    browserName = 'Firefox';
    version = userAgent.match(/Firefox\/(\d+\.\d+)/)?.[1];
  } else if (userAgent.includes('Edg')) {
    browserName = 'Edge';
    version = userAgent.match(/Edg\/(\d+\.\d+)/)?.[1];
  } else if (userAgent.includes('OPR')) {
    browserName = 'Opera';
    version = userAgent.match(/OPR\/(\d+\.\d+)/)?.[1];
  } else if (userAgent.includes('Trident')) {
    browserName = 'Internet Explorer';
    version = userAgent.match(/rv:(\d+\.\d+)/)?.[1];
  }

  return { browserName, version };
};

// THIS FUNCTION IS FOR USER COMMON DIMENSIONS TO LOG IN MATOMO AND GA ANALYTICS
export const getUserCommonDimensions = (user, device, networkType) => {
  const browserData = getBrowserInfo();

  const commonDiemnsions = [
    { id: visitDimensionIds.user_id, value: user?.id ?? 'Anonymous' },
    {
      id: visitDimensionIds.user_plan_type,
      value: user?.currentSubscriptionPlan?.frequency ?? null
    },
    { id: visitDimensionIds.user_plan, value: user?.effectivePlan ?? null },
    { id: visitDimensionIds.device_id, value: device?.id },
    {
      id: visitDimensionIds.device_type,
      value: device?.type === 'mobile' ? 'Mobile_web' : 'Web'
    },
    { id: visitDimensionIds.device_model, value: device?.model },
    { id: visitDimensionIds.os_type, value: device?.os?.name },
    { id: visitDimensionIds.os_version, value: device?.os?.version },
    { id: visitDimensionIds.network_type, value: networkType },
    { id: visitDimensionIds.browser, value: browserData?.browserName },
    { id: visitDimensionIds.browser_version, value: browserData?.version },
    {
      id: visitDimensionIds.timezone,
      value: Intl.DateTimeFormat().resolvedOptions().timeZone
    },
    { id: visitDimensionIds.web_version, value: AppInfo?.version }
  ];

  return commonDiemnsions;
};
