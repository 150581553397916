import { useMatomo } from '@datapunt/matomo-tracker-react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import {
  actionDimensionIds,
  MATOMO_ACTION,
  MATOMO_CATEGORY,
  MATOMO_NAME,
  PAGE_SLUG
} from '../common/constants';
import useDeviceInfo from '../common/useDeviceInfo';
import useNetworkType from '../common/useNetworkInfo';
import { getUserCommonDimensions } from '../common/utils';
import { useApp } from '../providers/AppContext';

function CustomLink({ type, name, url, target, internalPageType, icon }) {
  const device = useDeviceInfo();
  const networkType = useNetworkType();
  const {
    state: { currentUser }
  } = useApp();
  const { trackEvent } = useMatomo();
  const router = useRouter();

  const formLinkDimensions = () => {
    const userDimensions = getUserCommonDimensions(
      currentUser,
      device,
      networkType
    );
    const contentObjectForLink = [
      { id: actionDimensionIds.page_source, value: router.asPath },
      { id: actionDimensionIds.cta_target, value: url },
      ...userDimensions
    ];
    return contentObjectForLink;
  };

  if (type === 'INTERNAL')
    return (
      <Link
        prefetch={false}
        target={target}
        href={`${PAGE_SLUG[internalPageType] ?? ''}${
          url === '/' ? '' : '/'
        }${url}`}
        onClick={() => {
          trackEvent({
            category: MATOMO_CATEGORY.MENU,
            action: MATOMO_ACTION.CLICK,
            name: MATOMO_NAME.MENU_CLICK,
            customDimensions: formLinkDimensions()
          });
        }}
      >
        {icon} {name}
      </Link>
    );
  return (
    <a target={target} href={url} rel="noopener noreferrer">
      {icon} {name}
    </a>
  );
}

export default CustomLink;
