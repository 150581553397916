import { useMatomo } from '@datapunt/matomo-tracker-react';
import { useRouter } from 'next/router';
import {
  actionDimensionIds,
  MATOMO_ACTION,
  MATOMO_CATEGORY,
  MATOMO_NAME
} from '../common/constants';
import useDeviceInfo from '../common/useDeviceInfo';
import useNetworkType from '../common/useNetworkInfo';
import { getUserCommonDimensions } from '../common/utils';
import { useApp } from '../providers/AppContext';

function CustomAnchor({ href, children, className }) {
  const linkHref = href;
  const { trackEvent } = useMatomo();
  const device = useDeviceInfo();
  const networkType = useNetworkType();
  const router = useRouter();
  const {
    state: { currentUser }
  } = useApp();

  const formLinkDimensions = () => {
    const userDimensions = getUserCommonDimensions(
      currentUser,
      device,
      networkType
    );
    const contentObjectForLink = [
      { id: actionDimensionIds.page_source, value: router.asPath },
      { id: actionDimensionIds.cta_target, value: href?.pathname || href },
      ...userDimensions
    ];
    return contentObjectForLink;
  };
  function customLinkOnClick(e) {
    e.preventDefault();
    router.push(linkHref);
    trackEvent({
      category: MATOMO_CATEGORY.LINK,
      action: MATOMO_ACTION.CLICK,
      name: MATOMO_NAME.LINK_CLICK,
      customDimensions: formLinkDimensions()
    });
  }

  return (
    <a href={linkHref} onClick={customLinkOnClick} className={className}>
      {children}
    </a>
  );
}

export default CustomAnchor;
